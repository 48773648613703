<template>
  <div />
</template>

<script>

import oricidApi from '@/dl_pubmill/apis/orcid'

export default {
  created() {
    /* note, if needed, comment all this method to manage lcoalhost cookie*/
    // TODO dynamically assign <dev/prod>
    const { apiUriPrefix } = this.$store.state.dlStore
    const apiUri = apiUriPrefix.concat('auth')
    const { host } = window.location
    const prot = host.includes('localhost') ? 'http://' : 'https://'
    const curr = window.location.href
    console.log('In DLAuth.vue created curr = ', curr)
    
    //******************************** 
    //PUBMILL LOGIN WORKFLOW below
    //******************************** 
    if (curr.includes('/auth/login')) {
      this.bringUserToLoginForm(apiUri, prot, host)
    } else if (curr.includes('/auth/logout')) {
      this.logoutUser(apiUri, prot, host)
    } else if (curr.includes('/auth/rd') && curr.includes('#id_token')) {
      this.backFromLoginForm(apiUri, prot, host)
    } else if (curr.includes('/auth/rd') && curr.includes('/wd_token')) {
      this.backFromTokenConversion()
    } else if (curr.includes('/auth/rd') && curr.includes('?logout=')) {
      this.backFromLogout()
    //******************************** 
    //ORCID LOGIN WORKFLOW below
    //********************************
    } else if (curr.includes('/auth/orcid-login')) {
      if (host.includes('localhost')) {
        console.log('localhost bypass orcid lookup')
        const dummyOrcidRecord = {
          orcidId: 'localhost-test',
          name: 'Local Tester'
        }
        this.backFromGetOrcidRecord(dummyOrcidRecord)
        return
      }
      let dest = 'https://orcid.org/oauth/authorize?client_id=APP-PKCXS26WV158PDRZ&response_type=code&scope=/authenticate&redirect_uri=https://submission.versionofrecord.com/pubmill/auth/orcid-rd/:function/:pubIssn'
      dest = dest.replace(':function', this.$route.params.function)
      dest = dest.replace(':pubIssn', this.$route.params.pubIssn)
      console.log('bringUserToOricidLogin', dest)
      window.location.href = dest
    } else if (curr.includes('/auth/orcid-rd')) {
      console.log('back from orcid authentication')
      const authCode = curr.split('?code=')[1]
      console.log(this.$route.params.function, this.$route.params.pubIssn, authCode)
      this.getOrcidRecord(authCode)
    }

  },
  methods: {
    /* User clicks login (route='/?????/auth/login) and  is taken to api.vuexy-aws-base.com/<env>/auth?login=y
       with an rd param for for redirect : .athenable. com/????/auth/rd
      (note  api. athenable. com will redirect  to cognito ui on login.vuexy-aws-base.com)
    */
    bringUserToLoginForm(apiUri, prot, host) {
      const dest = apiUri.concat('?login=y&rd=', prot, host, '/pubmill/auth/rd')
      console.log('bringUserToLoginForm', dest)
      window.location.href = dest
    },
    logoutUser(apiUri, prot, host) {
      const dest = apiUri.concat('?logout=y&rd=', prot, host, '/pubmill/auth/rd?logout=y')
      console.log(' logoutUser dest', dest)
      window.location.href = dest
    },

    /* After successful cognito login, user is redirected back to (route='/?????/auth/rd)
       The #id-token is converted to &id-token  and the user is taken to is taken to
       api. athenable. com/auth?authrd=y with an rd param forredirect :
       . athenable. com/????/auth/rd as before
    */
    backFromLoginForm(apiUri, prot, host) {
      const spl = window.location.href.split('#id_token=')
      const suff = spl[1]
      const dest = apiUri.concat('?authrd=y&id_token=', suff, '&rd=', prot, host, '/pubmill/auth/rd/wd_token')
      console.log('back with #id_token redirecting to dest', dest)
      window.location.href = dest
    },

    backFromTokenConversion() {
      console.log('in Auth.vue.backFromTokenConversion 6666666666666666666666666666666666666', localStorage.getItem('deepLink'))

      let deepLink = '/'
      if (localStorage.getItem('deepLink')) {
        deepLink = localStorage.getItem('deepLink')
        localStorage.removeItem('deepLink')
      }

      console.log('deepLink', deepLink)

      const { host } = window.location
      const prot = host.includes('localhost') ? 'http://' : 'https://'
      const dest = prot.concat(host, deepLink)
      console.log('dest', dest)
      window.location.href = dest
    },

    backFromLogout() {
      const { host } = window.location
      const prot = host.includes('localhost') ? 'http://' : 'https://'
      const dest = prot.concat(host, '/')
      console.log('backFromLogout', dest)
      window.location.href = dest
    },

    getOrcidRecord (authCode) {
      //console.log('staging ddd issueFileNames', issueFileNames, 'dois', dois)
      oricidApi.getOrcidRecord(authCode, this, null)
    },
    
    backFromGetOrcidRecord (serverData) {
      if (serverData.error) {
        console.log('backFromGetOrcidRecord with error', serverData.error)
        return
      }
      console.log('backFromGetOrcidRecord',  serverData)  
      console.log(this.$route.params.function, this.$route.params.pubIssn)

      //cannot store here given the redirect below - using sessionStore instead
      //this.$store.commit('pubmillGlobalStore/updateOrcidRecord', serverData)
      sessionStorage.setItem('orcidId', serverData.orcidId)
      sessionStorage.setItem('orcidName', serverData.name)
  
      const { host } = window.location
      const prot = host.includes('localhost') ? 'http://' : 'https://'
      const dest = prot.concat(host, '/', this.$route.params.function, '/', this.$route.params.pubIssn)

      console.log('redirecting to dest', dest)
      window.location.href = dest
    }, 
  },
}
</script>
