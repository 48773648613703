import axios from 'axios'
import store from '@/store'
import apiUtil from './apiUtil'

// Athenable-Dependency here
// todo connect to external api
export default {

  getOrcidRecord(authToken, callbackComponet, loadingContainer) {
    //const { token } = store.state.dlStore.AppActiveUser
    const { apiUriPrefix } = store.state.dlStore
    let apiUrl = apiUriPrefix.concat('submissions?orcid=y&getRecord=y&authToken=:authToken&subms_tk=', '123')
    apiUrl = apiUrl.replace(':authToken', authToken)

    console.log('calling ttttt getOrcidRecord:', apiUrl)

    apiUtil.query_pending(true, callbackComponet, loadingContainer)
    axios
      .get(apiUrl)
      .then(response => {
        // console.log('resonse 1111', response.data)
        callbackComponet.backFromGetOrcidRecord(response.data)
      })
      .catch(error => {
        callbackComponet.backFromGetOrcidRecord(apiUtil.handleError(error))
      })
      .finally(() => {
        apiUtil.query_pending(false, callbackComponet, loadingContainer)
      })
  },
}
